import globalConfig from "../config/index";
const apiUrl = globalConfig.server;

export default {
  // 团检
  jump2Personal: `${apiUrl}/identity/clientAuth/jump2Personal`, // 跳转个人版，获取Token的Key
  login: `${apiUrl}/identity/clientAuth/login`, // 企业登录
  uniteLogin: `${apiUrl}/identity/clientAuth/unite/login`, // 渠道登录
  smsLogin: `${apiUrl}/identity/clientAuth/smsLogin`, // 客户端验证码登录
  SSOLogin: `${apiUrl}/identity/clientAuth/SSOLogin`, // 单点登入
  oppoLogin: `${apiUrl}/identity/clientAuth/oppo/login`, // 单点登入
  vivoLogin: `${apiUrl}/identity/clientAuth/vivo/login`, // 单点登入  -  vivo
  smsSend: `${apiUrl}/gen/sms/send`, // 发送短信
  uploadFile: `${apiUrl}/gen/file/upload`, // 上传文件
  delFile: `${apiUrl}/gen/file/delete`, // 删除文件
  wechatOauth: `${apiUrl}/identity/oauth/wechat`, // 微信授权
  getOrgInfo: `${apiUrl}/system/sysOrg/client/getByCode`, // 根据公司code获取公司信息
  getOrgList: `${apiUrl}/system/sysOrg/client/list`, // 根据登入人获取关联企业
  changeToken: `${apiUrl}/identity/clientAuth/unite/token/change`, // 企业切换置换token
  getWechatJSSDK: `${apiUrl}/gen/jssdk/config`, // 获取微信jssdk
  getFamilyList: `${apiUrl}/employer/member/client/list`, // 获取体检数据列表
  getFamilyDetail: `${apiUrl}/employer/member/client/get`, // 获取体检用户信息详情
  updateFamily: `${apiUrl}/employer/member/client/update`, // 修改体检用户家属数据
  addFamily: `${apiUrl}/employer/member/client/addFamily`, // 新增常用体检人
  getMedicalpackage: `${apiUrl}/employer/physicalPackage/client/list`, // 获取人员套餐列表
  getHospitalList: `${apiUrl}/employer/physicalPackage/client/getSupportHospitals`, // 获取机构
  getNearestHospitalList: `${apiUrl}/employer/healthProduct/client/getNearestHospitals`, // 获取最近距离的机构
  locationCity: `${apiUrl}/gen/region/client/cities`, // 获取切换定位城市
  getRegion: `${apiUrl}/gen/region/client/get`, // 获取获取省市区
  getPackageInfo: `${apiUrl}/employer/physicalPackage/client/get`, // 获取套餐详情
  getMedicalOrgDateInfo: `${apiUrl}/employer/physicalPackage/client/getSchedule`, // 获取某个机构排期
  getOptionalPackageInfo: `${apiUrl}/employer/physicalPackage/client/getOptionalItems`, // 获取套餐加项数据
  getPackageList: `${apiUrl}/employer/physicalPackage/client/list`, // 获取人员套餐列表
  getPhysicalPackageOnlyInfo: `${apiUrl}/employer/physicalPackage/client/getOnlyInfo`, // 获取套餐详情 - 不涉及机构

  // 套餐价格
  getMedicalPackagePrice: `${apiUrl}/employer/physicalPackage/client/getPrice`, // 获取价格
  getPreOrderInfo: `${apiUrl}/ordering/physicalOrder/client/getPreOrderInfo`, // 客户端订单预信息（下单前的信息汇总）
  submitOrder: `${apiUrl}/ordering/physicalOrder/client/add`, // 新增预约订单
  getOrderList: `${apiUrl}/ordering/base/client/list`, // 客户端获取统一订单列表
  getOrderQuantity: `${apiUrl}/ordering/base/client/quantity`, // 客户端获取统一订单数量
  getOrderDetail: `${apiUrl}/ordering/physicalOrder/client/get`, // 获取订单详情
  cancelOrder: `${apiUrl}/ordering/physicalOrder/client/cancel`, // 客户端取消体检订单
  modifiedOrderDate: `${apiUrl}/ordering/physicalOrder/client/change`, // 修改订单预约时间
  appointCheck: `${apiUrl}/ordering/physicalOrder/client/appointCheck`, // 客户端体检预约校验数据

  // 公告
  getNoticeBannerList: `${apiUrl}/employer/employerNotice/client/getNoticeBannerList`, // 获取通知公告Banner列表
  getNoticeMarqueeList: `${apiUrl}/employer/employerNotice/client/getNoticeMarqueeList`, // 获取通知公告跑马灯列表
  getNoticePopUpList: `${apiUrl}/employer/employerNotice/client/getNoticePopUpList`, // 获取通知弹框列表
  noticeRead: `${apiUrl}/employer/employerNotice/client/noticeRead`, // 读取消息
  noticePageList: `${apiUrl}/employer/employerNotice/client/query`, // 获取通知公告分页列表
  getNotice: `${apiUrl}/employer/employerNotice/client/get`, // 获取通知公告实体类
  updatePwd: `${apiUrl}/employer/member/client/modifyPwd`, // 修改密码
  proceedPay: `${apiUrl}/ordering/physicalOrder/client/proceedPay`, // 继续支付

  // 意见反馈
  getFeedbackList: `${apiUrl}/employer/feedback/client/query`, // 获取当前企业人员的反馈建议
  addFeedback: `${apiUrl}/employer/feedback/client/add`, // 增加反馈建议
  updateFeedback: `${apiUrl}/employer/feedback/client/updateRes`, // 更新反馈结果
  getFeedbackDetail: `${apiUrl}/employer/feedback/client/get`, // 客户端获取意见反馈详情
  getUnReadMessage: `${apiUrl}/employer/feedback/client/getUnReadMessage`, // 获取未读意见反馈消息数量
  forgetPassword: `${apiUrl}/employer/member/client/forgetPwd`, // 找回密码
  uploadAvatar: `${apiUrl}/employer/member/client/uploadAvatar`, // 客户端 - 个人中心上传头像
  modifyPhone: `${apiUrl}/employer/member/client/modifyPhone`, // 客户端 - 个人中心修改绑定手机号码
  checkIsPerfect: `${apiUrl}/employer/member/client/checkIsPerfect`, // 检查信息是否完善
  modifyPhoneTwo: `${apiUrl}/employer/member/client/modifyPhoneTwo`, // 客户端 - 个人中心修改绑定手机号码
  checkDeleteFamily: `${apiUrl}/employer/member/client/checkDeleteFamily`, // 客户端 - 校验是否可以删除家属
  deleteFamily: `${apiUrl}/employer/member/client/deleteFamily`, // 客户端 - 删除家属

  // 报告
  getUnReadReportNum: `${apiUrl}/report/physicalReport/client/getUnReadReportNum`, // 获取当前人员包含其家属生成的未读报告数量
  physicalReportList: `${apiUrl}/report/physicalReport/client/query`, // 获取人员报告数据
  physicalReportDetail: `${apiUrl}/report/physicalReport/client/get`, // 获取客户报告详情数据
  uploadReport: `${apiUrl}/report/physicalReport/client/upload`, // 客户端上传报告
  proceedUploadReport: `${apiUrl}/report/physicalReport/client/proceedUpload`, // 客户端继续上传报告（图片类型的才可以）
  downloadReport: `${apiUrl}/report/physicalReport/client/download`, // 客户端下载报告
  reportLogin: `${apiUrl}/report/physicalReport/client/grant`, // 客户端报告授权
  reservationExplain: `${apiUrl}/report/physicalReport/client/reservationExplain`, // 预约解读
  getReservationExplain: `${apiUrl}/report/physicalReport/client/getReservationExplain`, // 获取预约解读详情
  getTimeSlotQuota: `${apiUrl}/report/physicalReport/client/getTimeSlotQuota`, // 根据时间段获取名额
  getTimeSlotData: `${apiUrl}/report/physicalReport/client/getTimeSlotData`, // 获取可约天数数据源

  // 企业隐私条款管理
  checkPrivacyTerm: `${apiUrl}/employer/privacyTerm/client/check`, // 客户端校验最新版本的隐私条款
  getNewestPrivacyTerm: `${apiUrl}/employer/privacyTerm/client/getNewest`, // 客户端获取最新版本的隐私条款
  getPrivacyTerm: `${apiUrl}/employer/privacyTerm/client/get`, // 客户端获取最新版本的隐私条款
  agreePrivacyTerm: `${apiUrl}/employer/privacyTerm/client/agree`, // 客户端同意隐私条款

  // 收货地址
  addAddress: `${apiUrl}/employer/address/client/add`, // 新增收货地址
  deleteAddress: `${apiUrl}/employer/address/client/delete`, // 删除收货地址
  updateAddress: `${apiUrl}/employer/address/client/update`, // 修改收货地址
  setAddressDefault: `${apiUrl}/employer/address/client/setDefault`, // 设置默认收货地址
  getAddressDetail: `${apiUrl}/employer/address/client/get`, // 获取收货地址详情
  getAddressPageList: `${apiUrl}/employer/address/client/query`, // 获取收货地址列表
  getAddressList: `${apiUrl}/employer/address/client/list`, // 获取收货地址数据列表

  // 实物订单
  createGoodsOrder: `${apiUrl}/ordering/goods/client/add`, // 客户端下实物订单
  getPreGoodsOrderInfo: `${apiUrl}/ordering/goods/client/getPreInfo`, // 下实物订单前信息
  getGoodsOrderDetail: `${apiUrl}/ordering/goods/client/get`, // 获取订单详情
  cancelGoodsOrder: `${apiUrl}/ordering/goods/client/cancel`, // 取消实物订单
  confirmGoodsOrder: `${apiUrl}/ordering/goods/client/confirm`, // 确认收货实物订单
  proceedpayGoodsOrder: `${apiUrl}/ordering/goods/client/proceedpay`, // 实物订单继续支付
  confirmGoodsOrderInfo: `${apiUrl}/ordering/goods/client/confirmInfo`, // 确认订单信息(实物待确认)
  modifyGoodsOrderAddress: `${apiUrl}/ordering/goods/client/modifyAddress`, // 客户端修改地址
  onRevokeGoods: `${apiUrl}/ordering/goods/client/revoke`, // 撤销退款申请
  // 健康产品
  getHealthProductDetail: `${apiUrl}/employer/healthProduct/client/get`, // 获取健康产品详情
  getHealthProductList: `${apiUrl}/employer/healthProduct/client/list`, // 获取健康产品列表

  // 医疗服务订单
  getPreMedicalOrderInfo: `${apiUrl}/ordering/medical/client/getPreInfo`, // 客户端订单预信息（下单前的信息汇总）
  createMedicalOrder: `${apiUrl}/ordering/medical/client/add`, // 客户端下订单
  getMedicalOrderDetail: `${apiUrl}/ordering/medical/client/get`, // 获取服务订单详情
  getMedicalOrderSupportHospitals: `${apiUrl}/ordering/medical/client/getSupportHospitals`, // 获取订单支持的机构
  appointMedicalOrder: `${apiUrl}/ordering/medical/client/appoint`, // 客户端订单预约
  getMedicalOrderSchedule: `${apiUrl}/ordering/medical/client/getSchedule`, // 客户端获取机构排期
  changeMedicalOrderSchedule: `${apiUrl}/ordering/medical/client/change`, // 客户端改约服务订单
  refundMedicalOrder: `${apiUrl}/ordering/medical/client/refund`, // 客户端退款服务订单
  cancelMedicalOrder: `${apiUrl}/ordering/medical/client/cancel`, // 客户端取消服务订单
  cancelAppointMedicalOrder: `${apiUrl}/ordering/medical/client/cancelAppoint`, // 客户端取消服务订单预约信息
  proceedPayMedicalOrder: `${apiUrl}/ordering/medical/client/proceedPay`, // 客户端继续支付

  submitOrderProductForm: `${apiUrl}/ordering/medical/client/submitOrderProductForm`, //  绿通订单提交预约信息
  getOrderProductForm: `${apiUrl}/ordering/medical/client/getOrderProductForm`, //  绿通订单获取预约信息
  confirmOrder: `${apiUrl}/ordering/medical/client/seal`, //  绿通订单确认订单
  getTimeReservation: `${apiUrl}/employer/physicalPackage/client/getTime`, // 获取套餐机构排期时间段

  getPayPreByNo: `${apiUrl}/ordering/base/client/getPayPreByNo`, // 获取支付信息
  getPayPreByNoAnonymous: `${apiUrl}/ordering/base/client/anonymous/getPayPreByNo`, // 获取支付信息 -- 匿名
  toPay: `${apiUrl}/ordering/base/client/toPay`, // 支付接口

  // 健康短文
  getHealthArticleList: `${apiUrl}/system/healthArticle/client/list`, // 客户端获取所有健康短文
  getHealthArticleDetail: `${apiUrl}/system/healthArticle/client/get`, // 客户端获取健康短文实体类
  getHealthArticlePageList: `${apiUrl}/system/healthArticle/client/query`, // 客户端获取健康短文分页

  // 获取配置 -- 业务员给企业设置
  employerGeneralSettingsList: `${apiUrl}/employer/employerGeneralSettings/client/query`, // 获取规则(单独使用)
  // 体检预约验证
  appointmentverification: `${apiUrl}/ordering/physicalOrder/client/appointmentverification`, // 体检预约验证

  // 获取品牌列表
  brandLogList: `${apiUrl}/report/brandLog/client/list`, // 获取品牌列表
  addbrandLog: `${apiUrl}/report/brandLog/client/add`, // 手动添加品牌
  // 获取解析列表
  queryAnalyze: `${apiUrl}/report/analyze/query`, // 获取解析列表
  cancelReservation: `${apiUrl}/report/physicalReport/client/cancelReservation`, // 取消预约

  // 获取ocr解析结果
  getAnalysisResults: `${apiUrl}/report/physicalReport/getAnalysisResults`, // 获取ocr解析结果

  // 自定义页面管理服务
  getPageConfig: `${apiUrl}/system/page/client/pageConfig`, // 客户端获取企业页面配置
  getPage: `${apiUrl}/system/page/client/get`, // 客户端获取页面样式

  // 初始化未解析的体检报告
  initOCRAnalysis: `${apiUrl}/report/physicalReport/initOCRAnalysis`, // 初始化未解析的体检报告

  // 获取最新的未提交预约解读报告
  queryNotSumbitAnalyzeReport: `${apiUrl}/report/physicalReport/client/queryNotSumbitAnalyzeReport`, // 获取最新的未提交预约解读报告

  getMemberAsset: `${apiUrl}/employer/asset/getMemberAsset`, // 获取人员折扣余额
  getAllowAnonymou: `${apiUrl}/ordering/physicalOrder/client/anonymous/get`, // 客户端获取订单详情
  toPayAnonymous: `${apiUrl}/ordering/base/client/anonymous/toPay`, // HR帮助预约订单统一支付
  getEmployerAuth: `${apiUrl}/employer/employerAuth/client/get`, // 客户端获取授权书实体类
  addEmployerAuth: `${apiUrl}/employer/employerAuth/client/add`, // 新增

  // 标签列表
  hftagList: `${apiUrl}/supplier/hftag/client/list`, // 获取标签集合
  getHftagDetail: `${apiUrl}/supplier/hftag/client/get`, // 获取标签详情

  /**
   * rights
   * 权益订单接口服务
   */
  clientList: `${apiUrl}/ordering/rights/client/list`, // 客户端获取订单列表数据
  getClient: `${apiUrl}/ordering/rights/client/get`, // 客户端获取订单详情
  clientCancel: `${apiUrl}/ordering/rights/client/cancel`, // 客户端取消订单
  clientActive: `${apiUrl}/ordering/rights/client/active`, // 客户端激活订单
  clientGetCard: `${apiUrl}/ordering/rights/client/getCard`, // 客户端获取卡片信息
  clientGetCardByMemberRightId: `${apiUrl}/ordering/rights/client/getCardByMemberRightId`, // 通过人员权益id获取卡片信息

  /**
   * rights
   * 权益服务
   */
  getEmployerRights: `${apiUrl}/employer/rights/client/get`, // 客户端权益详情查询

  // 加项包
  getAddPackageItem: `${apiUrl}/employer/addPackage/client/getAddPackageItem`, // 获取加项包项目
  checkAddOnPackageRepeat: `${apiUrl}/employer/addPackage/client/checkAddOnPackageRepeat`, // 加项包项目是否重复
  getSupportHospitalSchedules: `${apiUrl}/employer/physicalPackage/client/SupportHospitalSchedules`, // 获取机构排期
  checkFamilyPackage: `${apiUrl}/employer/physicalPackage/client/checkFamilyPackage`, //  查找已经配置的家人套餐数量

  // 注册
  signUpClientAuth: `${apiUrl}/identity/clientAuth/signUp`, // 企业员工注册

  // 获取健康险详情
  getHealthInsDetail: `${apiUrl}/ordering/healthInsuranceProduct/get`, // 获取健康险详情

  // 问卷
  getNewestQuestion: `${apiUrl}/report/questionnaire/client/getNewest`, // 获取问卷详情
  getAnswering: `${apiUrl}/report/questionnaire/client/answering`, // 获取指定类型的最新问卷
  continueAnswering: `${apiUrl}/report/questionnaire/client/continue`, // 继续答卷
  stopAnswering: `${apiUrl}/report/questionnaire/client/stop`, // 结束答卷
  questioEvaluating: `${apiUrl}/report/questionnaire/client/evaluating`, // 问卷评估
  getRecordAnalyze: `${apiUrl}/report/questionnaire/client/getRecordAnalyze`, // 判断是否有问卷记录
  getQuestionlist: `${apiUrl}/report/questionnaire/client/list`, // 历史预测
  checkQuestion: `${apiUrl}/report/questionnaire/client/check`, // 校验客户端是否有有效问卷

  checkHasEffOrder: `${apiUrl}/ordering/physicalOrder/client/checkHasEffOrder`, // 是否有有效订单

  // 订单评价部分
  getOrderingEvaluate: `${apiUrl}/ordering/orderEvaluation/client/get`, // 客户端获取评价详情
  orderingEvaluateBefore: `${apiUrl}/ordering/orderEvaluation/client/beforeAdd`, // 客户端提交订单评价前获取订单基本信息
  orderingEvaluateAdd: `${apiUrl}/ordering/orderEvaluation/client/add`, // 客户端提交订单评价

  // 体检报告服务
  checkIdentity: `${apiUrl}/report/physicalReport/client/checkIdentity`, // 确认客户是否已经验证过是本人(验证 + 是否有报告，无报告也会返回true)
  checkAuth: `${apiUrl}/report/physicalReport/client/checkAuth`, // 确认客户是否已经验证过是本人(只校验验证)
  sendConfirm: `${apiUrl}/report/physicalReport/client/sendConfirm`, // 客户发送确认为本人验证码
  confirmIdentity: `${apiUrl}/report/physicalReport/client/confirmIdentity`, // 客户发送确认为本人
  sendQueryUnAuth: `${apiUrl}/report/physicalReport/client/sendQueryUnAuth`, // 客户发送未授权报告验证码
  queryUnAuth: `${apiUrl}/report/physicalReport/client/queryUnAuth`, // 客户端查询未授权报告
  authReport: `${apiUrl}/report/physicalReport/client/authReport`, // 客户端授权报告

  // 报销申请
  submitReimbursement: `${apiUrl}/employer/memberReimbursement/client/add`, // 提交报销申请
  revokeReimbursement: `${apiUrl}/employer/memberReimbursement/client/revoke`, // 撤销报销申请
  queryReimbursement: `${apiUrl}/employer/memberReimbursement/client/query`, // 查询报销数据
  getBankName: `${apiUrl}/gen/bank/name`, // 根据卡号获取银行名称
  getBankList: `${apiUrl}/gen/bank/list`, // 获取银行列表
  getApptNotice: `${apiUrl}/supplier/apptNotice/client/get`, // 获取预约须知
  readApptNotice: `${apiUrl}/supplier/apptNotice/client/read`, // 阅读预约须知
  resetRead: `${apiUrl}/supplier/apptNotice/client/resetRead`, // 重置预约须知

  // 是否可以修改婚姻状态
  isUpdateMarriageStatus: `${apiUrl}/employer/member/client/isUpdateMarriageStatus`, // 是否可以修改婚姻状态
  updateMarriageStatus: `${apiUrl}/employer/member/client/updateMarriageStatus`, // 更新婚姻状态

  // 检前须知是否展示
  isShowPhysicalNotice: `${apiUrl}/employer/member/client/isShowPhysicalNotice`, // 是否展示检前须知
  confirmPhysicalNotice: `${apiUrl}/employer/member/client/confirmPhysicalNotice`, // 确认检前须知

  // 浙商银行登录
  bestdoLogin: `${apiUrl}/identity/clientAuth/bestdoLogin`, // 登录

  // 套餐
  singlePackage: `${apiUrl}/employer/physicalPackage/client/singlePackage`, // 是否展示套餐列表
  packageComparing: `${apiUrl}/employer/physicalPackage/client/packageComparing`, // 套餐对比

  // 钉钉授权登录
  dingTalkLogin: `${apiUrl}/identity/clientAuth/dingTalk/login`, // 钉钉授权登录

  // 获取品牌列表
  getSupplierBrandList: `${apiUrl}/report/brandLog/client/getSupplierBrandList`, // 获取品牌列表

  url2base64: `${apiUrl}/gen/file/url2base64`, // 通过图片链接获取base64
};
