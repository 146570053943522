
import { Component, Vue } from "vue-property-decorator";
import pageBack from "src/components/page-back";

@Component({
  components: {
    pageBack,
  },
})
export default class Login extends Vue {
  /**
   * 返回
   */
  private customFn() {
    this.$emit("close-rights-privacy");
  }
}
