import { render, staticRenderFns } from "./rights-privacy.vue?vue&type=template&id=ad1c02cc&scoped=true"
import script from "./rights-privacy.vue?vue&type=script&lang=ts"
export * from "./rights-privacy.vue?vue&type=script&lang=ts"
import style0 from "./rights-privacy.vue?vue&type=style&index=0&id=ad1c02cc&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ad1c02cc",
  null
  
)

export default component.exports