
import { Component, Vue } from "vue-property-decorator";
import pageBack from "src/components/page-back";
import vtable from "src/components/vtable";
import globalConfig from "src/config/index";

@Component({
  components: {
    pageBack,
    vtable,
  },
})
export default class Login extends Vue {
  private data = [
    {
      type: "手机号码",
      scene: "用户注册、登录时",
      objective: "1、用于验证码发送（用于注册、登录、绑定账号、密码找回）2、用于业务通知发布及业务沟通",
      frequency: "使用时调用",
    },
    {
      type: "微信账号信息（微信Open ID）",
      scene: "1、用户获取授权登录时 2、用户支付时",
      objective: "用于广州畅心账号绑定",
      frequency: "使用时调用",
    },
    {
      type: "姓名、性别、证件类型、证件号码、年龄、手机号、婚否状况",
      scene: "用户购买、预约体检服务时",
      objective: "1、用于体检信息收集 2、用于体检产品信息关联",
      frequency: "使用时调用",
    },
    {
      type: "支付宝账号信息",
      scene: "用户支付时",
      objective: "用于广州畅心账号绑定",
      frequency: "使用时调用",
    },
    {
      type: "地址",
      scene: "用户购买、预约体检服务时",
      objective: "1、用于计算离用户最近的网点机构及推荐网点机构/医院 2、用于体检报告邮寄",
      frequency: "使用时调用",
    },
    {
      type: "账号信息",
      scene: "用户反馈意见时",
      objective: "用于身份识别验证",
      frequency: "使用时调用",
    },
  ];

  private header = [
    { title: "个人信息类型", prop: "type", width: "130", align: "center" },
    { title: "使用场景", prop: "scene", width: "130", align: "center" },
    { title: "使用目的及功能", prop: "objective", width: "250", align: "center" },
    { title: "频次", prop: "frequency", width: "100", align: "center" },
  ];
  /**
   * 返回
   */
  private customFn() {
    this.$emit("close-collect");
  }

  /**
   * 获取当前登入企业相关配置
   * @private
   * @returns void
   */
  protected get employerConfig(): any {
    let appid = localStorage.getItem("appid");

    let employerConfig = globalConfig.employerConfig[appid];

    return employerConfig;
  }
}
