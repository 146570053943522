
import { Component, Vue } from "vue-property-decorator";
import pageBack from "src/components/page-back";
import collect from "./attachment-file/collect.vue";
import uShare from "./attachment-file/share.vue";
import uSdk from "./attachment-file/sdk.vue";
import globalConfig from "src/config/index";

@Component({
  components: {
    pageBack,
    collect,
    uShare,
    uSdk,
  },
})
export default class privacy extends Vue {
  /**
   * 返回
   */
  private customFn() {
    if (!this.showCollect) {
      this.$emit("close-zfprivacy");
    }
  }

  /**
   * 个人信息收集清单
   */
  private showCollect: boolean = false;

  /**
   * 三方信息共享清单
   */
  private showShare: boolean = false;

  /**
   * SDK清单
   */
  private showSdk: boolean = false;

  /**
   * 显示个人信息收集清单
   */
  private onCollect() {
    this.showCollect = true;
  }

  /**
   * 显示三方信息共享清单
   */
  private onShare() {
    this.showShare = true;
  }

  /**
   * 显示SDK清单
   */
  private onSdk() {
    this.showSdk = true;
  }

  /**
   * 关闭个人信息收集清单
   */
  private closeCollect() {
    this.showCollect = false;
  }

  /**
   * 关闭第三方信息共享清单
   */
  private closeShare() {
    this.showShare = false;
  }

  /**
   * 关闭SDK清单
   */
  private closeSdk() {
    this.showSdk = false;
  }

  /**
   * 获取当前登入企业相关配置
   * @private
   * @returns void
   */
  protected get employerConfig(): any {
    let appid = localStorage.getItem("appid");

    let employerConfig = globalConfig.employerConfig[appid];

    return employerConfig;
  }
}
